import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/providers/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import './i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store/configure-store';
import { Provider } from 'react-redux';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        {/* <MemoryRouter> */}
        <Provider store={store}>
          <App />
        </Provider>
        {/* </MemoryRouter> */}
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
