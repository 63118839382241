export const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const REACT_APP_LOCAL_HOST = process.env.REACT_APP_LOCAL_HOST;
export const DEV_ENVIRONMENT = Boolean(process.env.REACT_APP_DEV_ENVIRONMENT);
export var API_ACCESS_TOKEN = '';

export const AUTH_CONFIG = {
  LOGIN_TYPE_GOOGLE: 'goog',
  LOGIN_TYPE_MICROSOFT: 'msft',
  LOGIN_TYPE_BASIC_AUTH: 'basic-auth',
  LOGIN_TYPE_ADMIN: `admin`,
  LOGIN_TYPE_USER: `user`,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export const REFRESH_TOKEN = `REFRESH_TOKEN `;
export const DECODED_TOKEN = `DECODED_TOKEN `;

export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN_OPERATOR: 'admin-operator',
  END_USER: 'end-user',
  TENANT_OPERATOR: 'tenant-operator',
  TENANT_ADMIN: 'tenant-admin'
};

export const USER_TYPE = {
  TENANT_USER: 't-u',
  ADMIN_USER: 'a-u'
};

export const ADMIN_ROLES = [
  {
    roleName: 'Super Admin',
    id: 'super-admin'
  },
  {
    roleName: 'Admin Operator',
    id: 'admin-operator'
  }
];
export const INVALID_OTP = 'Invalid OTP';
export const EMAIL_VERIFICATION_FAILED = 'Failed to verify email';
export const OTP_VERIFICATION_FAILED =
  'Failed to verify user verification code';
export const TENANT_ROLES = [
  {
    roleName: 'Tenant Admin',
    id: 'tenant-admin'
  },
  {
    roleName: 'Tenant Operator',
    id: 'tenant-operator'
  },
  {
    roleName: 'End User',
    id: 'end-user'
  }
];

export const getRole = (roleId) => {
  const roleList = [...ADMIN_ROLES, ...TENANT_ROLES];
  const filter = roleList.filter((role) => {
    return roleId === role.id;
  });
  if (filter.length > 0) {
    return filter[0].roleName;
  }
};

export const ACCESS_TOKEN = `ACCESS_TOKEN `;
export const CHAT_BOT_API_TOKEN = `chatBotApiToken`;

export const LIST_VIEW = {
  TABLE_VIEW: 'Table',
  CARD_VIEW: 'Card'
};

export const ASSET_TYPE = {
  SSH: 'SSH'
};
export const USER_TABLE = 'UserTable';

export const I18N = {
  DEFAULT: 'lang',
  ADMIN_USER: 'adminusermanagement',
  ORGANIZATION: 'organization',
  USER_MANAGEMENT: 'usermanagement',
  SIDE_BAR_MENU: 'sidebarmenu',
  SECRET_MANAGEMENT: 'secretmanagement',
  SSH_SERVER_MANAGEMENT: 'sshservermanagement',
  CHAT_BOT: 'chatbot',
  REPORTS: 'report'
};
export const SOURCE_BOT_TYPE = [
  { id: 'WEBSITE', sourceType: 'WEBSITE', botType: 'Web url' },
  { id: 'S3', sourceType: 'S3', botType: 'S3 Bucket' },
  { id: 'DATABASE', sourceType: 'DATABASE', botType: 'Database' }
];
export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  ALPHA_NUMBERICS: /^[a-zA-Z0-9-_ ]*$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/,
  URL: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})$/
};

export const FIELD_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  FREE_TEXT: 'freetext',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  ALPHA_NUMBERICS: 'alphanumerics',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group',
  UPLOAD_BUTTON: 'upload_button'
};

//below const is from seed, we can remove it if it is not breck any functionality

export const HEADER_AUTHORIZATION = 'a64bccc5-1648-46ae-ad78-b0f890f1d6c1';

export const DEVSTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const QASTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const CLINICALUSER_STAGE_USERPOOL_ID = 'us-west-2_VU5TDSRwJ';

export const DEVSTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const QASTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const CLINICALUSER__USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const LOGIN = import('src/auth/login/Login');
export const ADMIN_LOGIN = import('src/auth/login/Login');

export const TRANSACTIONS = import(
  'src/modules/Transactions/ApplicationsTransactions'
);
export const ORGANIZATION = import('src/modules/Organization/Organization');

export const ADMIN_MANAGEMENT = import(
  'src/modules/AdminManagement/AdminManagement'
);

export const USER_MANAGEMENT = import(
  'src/modules/UserManagement/UserManagement'
);

export const ASSESSMENT = import('src/modules/Assessment/Assessment');
export const REPORTS = import('src/modules/Reports/Reports');
export const SHOW_ALL_ASSESSMENT = import(
  'src/modules/ShowAllAssessment/ShowAllAssessment'
);

export const RECORD_ASSESSMENT = import(
  'src/modules/RecordAssessment/RecordAssessment'
);

export const SETTINGS = import('src/modules/Settings/Settings');

export const ADMIN_OPERATOR_USER_ASSETS = import(
  'src/modules/UserManagement/UserManagement'
);

export const SET_PASSWORD = import('src/auth/login/SetPassword');
export const FORGET_PASSWORD = import('src/auth/login/ForgotPassword');
export const ADMIN_FORGET_PASSWORD = import('src/auth/login/ForgotPassword');
export const CHECK_YOUR_EMAIL = import('src/auth/login/CheckYourEmail');

export const LANDING_PAGE = import('src/modules/LandingPage/LandingPage');

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress'
}

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const MEGABYTE_CONVERTER = 1000000;
export const MESSAGE = 'message';
export const STATUS_404 = '404';

export const accessTokenFunction = (token?: any) => {
  API_ACCESS_TOKEN = token;
};

//Report Mock Data
export const commonColors = {
  donutPrimaryColor: '#F2F3F8',
  donutSecondaryColor: '#DC189B',
  textPrimaryColor: '#F2B90D',
  textSecondaryColor: '#FADB9E',
  audioPrimaryColor: '#0DADF2',
  audioSecondaryColor: '#A1E5F7',
  videoPrimaryColor: '#973DE7',
  videoSecondaryColor: '#EDD8FF',
  textBarColor: '#F2B90D',
  audioBarColor: '#0DADF2',
  videoBarColor: '#973DE7',
  goodBgColor: '#edfaeb',
  poorBgColor: '#fbe9ef',
  averageBgColor: '#feece7',
  goodTextColor: '#3aac38',
  poorTextColor: '#d9265c',
  averageTextColor: '#ff6333',
  textIconBgColor: 'rgba(255, 250, 209, 1)',
  audioIconBgColor: 'rgba(218, 215, 254, 1)',
  videoIconBgColor: 'rgba(199, 243, 255, 1)'
};

export const mockVideoUrl =
  'https://assets.mixkit.co/videos/preview/mixkit-countryside-meadow-4075-large.mp4';

export const DonutColors = [
  commonColors.donutPrimaryColor,
  commonColors.donutSecondaryColor
];

export const width750 = 750;
export const height340 = 340;

export const themeColors = {
  primary: '#5569ff',
  secondary: '#6E759F',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57',
  mode: 'light'
};

export const questionStatus = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
};

export const values = {
  zero: 0,
  forty: 40,
  seventy: 70,
  hundred: 100
};

export const reportTextData = {
  GOOD: 'You covered most of the key points asked in the question. Your arguments were cogent and displayed a good understanding of the unit material.',
  AVERAGE:
    'You used the right vocabulary and covered the key points that were asked. You should focus on being more accurate as per the unit material.',
  POOR: 'You did not use the correct vocabulary in your response. Your answer was not aligned with the key information covered in this unit.'
};
export const reportVideoData = {
  GOOD: 'Your facial expressions and body language indicate an high degree of conviction.',
  AVERAGE:
    'Your facial expressions and body language indicate a average degree of conviction. More confident gestures and postures can be used to improve in this area.',
  POOR: 'Your facial expressions and body language indicate a low degree of conviction. More confident gestures and postures can be used to improve in this area.'
};
export const reportAudioData = {
  GOOD: 'The tone and pitch of your voice indicated a high degree of conviction.',
  AVERAGE:
    'The tone and pitch of your voice indicated a average degree of conviction.',
  POOR: 'The tone and pitch of your voice indicated a low degree of conviction.'
};
