import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  TenantUserType,
  UserData,
  UserLoginDetails,
  UserVerifyData,
  decodedToken,
  loginData
} from '../models/login';
import {
  AUTH_CONFIG,
  EMAIL_VERIFICATION_FAILED,
  OTP_VERIFICATION_FAILED,
  ROLES,
  USER_TYPE
} from '../../../shared/constants/constants';
import {
  loginAPI,
  loginWithOTP,
  logOutAPI,
  verifyEmailOTP
} from '../apis/login';
import { OrganizationData } from 'src/modules/Organization/models';
import { RootState } from 'src/store/reducer';
import { setAccessToken } from 'src/shared/utils/storage';
import jwt_decode from 'jwt-decode';
const initialState = {
  tenantId: null,
  unitId: null,
  userId: null,
  verificationCode: null,
  userData: <UserData>{},
  isUserVerify: false,
  isUserVerifyByTenant: false,
  email: '',
  //TODO remove below if not needed

  loginDomain: '',
  loginUserType: '',
  userInfo: <UserLoginDetails>{},
  authToken: '',
  loginUserRole: '',
  tenantUserRoles: <any>{},
  userRoleMap: {},
  loading: false,
  isError: false,
  errorMessage: '',
  decodedToken: <decodedToken>{},
  isLoginFailed: false,
  selectedOrganization: <OrganizationData>{},
  errorOtpObj: <any>{}
};

export const selectUserState = ({ user }: RootState) => ({
  tenantId: user?.tenantId,
  unitId: user?.unitId,
  userId: user?.userId,
  verificationCode: user?.verificationCode,
  isUserVerify: user?.isUserVerify,
  userData: user?.userData,
  isUserVerifyByTenant: user?.isUserVerifyByTenant,
  email: user?.email,
  //TODO remove below if not needed

  userInfo: user?.userInfo,
  loginUserType: user?.loginUserType,
  authToken: user?.authToken,
  loginUserRole: user?.loginUserRole,
  tenantUserRoles: user?.tenantUserRoles,
  loading: user?.loading,
  isError: user?.isError,
  errorMessage: user?.errorMessage,
  loginDomain: user?.loginDomain,
  decodedToken: user?.decodedToken,
  isLoginFailed: user?.isLoginFailed,
  selectedOrganization: user?.selectedOrganization
});

export const getLoginDetails = createAsyncThunk(
  'user/getLoginDetails',
  async (userData: object, { rejectWithValue }) => {
    try {
      const loginResponse = await loginAPI(userData);
      return loginResponse;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendVerificationCode = createAsyncThunk(
  'user/sendVerificationCode',
  async (payload: loginData, { rejectWithValue }) => {
    try {
      const response = await loginWithOTP(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyUserVerificationCode = createAsyncThunk(
  'user/verifyUserVerificationCode',
  async (payload: UserVerifyData, { rejectWithValue }) => {
    try {
      const response = await verifyEmailOTP(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLogOutUser = createAsyncThunk(
  'user/getLogout',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await logOutAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setData: (state, action) => {
      state.userData = action.payload;
    },
    setTenantId: (state, action) => {
      state.tenantId = action.payload;
    },
    setUnitId: (state, action) => {
      state.unitId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsUserVerify: (state, action) => {
      state.isUserVerify = action.payload;
    },
    setIsUserVerifyByTenant: (state, action) => {
      state.isUserVerifyByTenant = action.payload;
    },
    setUserRole: (state, action) => {
      state.loginUserRole = action.payload;
    },
    resetData: (state) => {
      state.userData = <UserData>{};
      state.userInfo = <UserLoginDetails>{};
      state.loginUserRole = '';
      setAuthToken('');
    },
    setIsError: (state, action) => {
      state.errorMessage = '';
      state.isError = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setselectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setUserAndAccessTokenData: (state, action) => {
      state.userData = action.payload.userData;
      state.authToken = action.payload.token;
    },
    setLoginProvider: (state, action) => {
      state.loginDomain = action.payload;
    },
    setLoginEmail: (state, action) => {
      state.email = action.payload ?? null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationCode.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.errorOtpObj = {};
        state.isLoginFailed = false;
        state.userData = <UserData>{};
      })
      .addCase(sendVerificationCode.fulfilled, (state, action: any) => {
        state.loading = false;
        state.isError = false;
        if (action.payload) {
          state.userData = action.payload;
          state.userId = action.payload.id;
          state.verificationCode =
            action.payload.details.user_verification_code;
        }

        //TODO this is hardcoded token, we removed it when we impl authantication.
        // state.authToken =
        //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIwNzk4MTkxLCJpYXQiOjE3MjA3OTc4OTEsImp0aSI6ImEwMGM4ZDA3NDNlNzRjMjZiYWI0NDY1ODQ2ZDBhZmU1IiwidXNlcl9pZCI6MX0.F-tByoEucczJu1iyCYQABLKadUW3lp4XHwvd_BlunR0';
        // setAccessToken(state.authToken);
        const decodedToken: decodedToken = jwt_decode(
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIwNzk4MTkxLCJpYXQiOjE3MjA3OTc4OTEsImp0aSI6ImEwMGM4ZDA3NDNlNzRjMjZiYWI0NDY1ODQ2ZDBhZmU1IiwidXNlcl9pZCI6MX0.F-tByoEucczJu1iyCYQABLKadUW3lp4XHwvd_BlunR0'
        );
        state.decodedToken = decodedToken;
        // setDecodedToken(decodedToken);
        // setRefreshToken(
        //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcyMDg4NDI5MSwiaWF0IjoxNzIwNzk3ODkxLCJqdGkiOiI1NmYwYmUzNDBiZDM0Njc0YTRhNTNhMWFmZTcxNWZlMyIsInVzZXJfaWQiOjF9.HqRd8SF4Qp-UuC2BToprKiEX8eSUMQ8qJtM-lwzsLJU'
        // );
        if (state.decodedToken.role === ROLES.ADMIN_OPERATOR) {
          state.loginUserRole = ROLES.ADMIN_OPERATOR;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role === ROLES.SUPER_ADMIN) {
          state.loginUserRole = ROLES.SUPER_ADMIN;
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_ADMIN;
        } else if (state.decodedToken.type === AUTH_CONFIG.LOGIN_TYPE_USER) {
          state.loginUserType = AUTH_CONFIG.LOGIN_TYPE_USER;
          if (state.decodedToken.role) {
            state.loginUserRole = state.decodedToken.role;
          }
        }

        state.isLoginFailed = false;
      })
      .addCase(sendVerificationCode.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.isError = true;
        state.errorOtpObj.message =
          action.payload?.message || EMAIL_VERIFICATION_FAILED;
      })
      .addCase(verifyUserVerificationCode.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.errorMessage = '';
        state.isLoginFailed = false;
        state.userData = <UserData>{};
      })
      .addCase(verifyUserVerificationCode.fulfilled, (state, action: any) => {
        state.loading = false;
        state.isError = false;
        state.isUserVerify = true;
        state.email = action?.payload?.email;
      })
      .addCase(verifyUserVerificationCode.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.isError = true;
        state.errorMessage = action.payload?.message || OTP_VERIFICATION_FAILED;
        resetData();
      })

      .addCase(getLogOutUser.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.errorMessage = '';
        state.isLoginFailed = false;
      })
      .addCase(getLogOutUser.fulfilled, (state, action: any) => {
        state.loading = false;
        state.isError = false;
        if (action.payload) {
          state.userInfo = null;
          state.userData = null;
          state.authToken = null;
          state.decodedToken = null;
          state.loginUserRole = null;
          state.tenantUserRoles = null;
          state.isLoginFailed = false;
          setAccessToken(null);
        } else {
          state.isLoginFailed = false;
          state.isError = false;
          resetData();
        }
      })
      .addCase(getLogOutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.isError = true;
        state.errorMessage = action.payload?.message;
        resetData();
      });
  }
});

export const {
  setData,
  resetData,
  setAuthToken,
  setIsError,
  setselectedOrganization,
  setUserAndAccessTokenData,
  setLoginProvider,
  clearResults,
  setUserRole,
  setTenantId,
  setUnitId,
  setIsUserVerify,
  setUserId,
  setIsUserVerifyByTenant,
  setLoginEmail
} = userSlice.actions;

export default userSlice.reducer;
