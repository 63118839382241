import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React, { useState, useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import router from './router/router';
import SettingsIcon from '@mui/icons-material/Settings';
import * as ROUTES from './shared/constants/routes';
import './scss/commonStyle.scss';
import {
  Box,
  CssBaseline,
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  Menu,
  ThemeProvider
} from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { themeCreator } from '../src/core/theme/base';
import { persistor } from '../src/store/configure-store';
import { AuthProvider, useAuth } from './providers/AuthguardContext';
import { Select, showErrorMessage } from './shared/components/index';
import { ToastContainer } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import EventEmitter from './shared/utils/eventEmitter/eventEmiter';
import { PersistGate } from 'redux-persist/integration/react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getLogOutUser,
  resetData,
  selectUserState
} from './auth/login/services/userSlice';
import { Signout } from './shared/utils/signout';
import { resetLogoutSession } from './shared/utils/eventEmitter/logoutEmitter';
import { ThemeI } from './shared/utils/Interfaces';
import { createCustomTheme } from './core/theme/schemes/createCustomTheme';
const App = () => {
  const content = useRoutes(router);
  const curThemeName = localStorage.getItem('appTheme') || 'CustomTheme';
  const primaryColor = useSelector(
    (state: ThemeI) => state?.theme?.primary ?? '#5569ff'
  );
  const [themeName, _setThemeName] = useState(curThemeName);
  let theme;
  if (curThemeName === 'CustomTheme') {
    theme = createCustomTheme({ primaryColor });
  } else {
    theme = themeCreator(themeName);
  }
  const [getLoader, setLoader] = React.useState(false);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTheme = event.target.value;
    localStorage.setItem('appTheme', currentTheme);
    _setThemeName(currentTheme);
  };
  const { userData } = useSelector(selectUserState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch<any>();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const themeOptions = [
    {
      id: 'PureLightTheme',
      name: 'PureLightTheme'
    },

    {
      id: 'NebulaFighterTheme',
      name: 'NebulaFighterTheme'
    }
  ];

  const configsButton = (
    <Box className="configsButton">
      <IconButton onClick={handleClick} sx={{ color: 'black' }}>
        <SettingsIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <ListItemText sx={{ padding: '15px' }}>Global Settings</ListItemText>
        <Divider />
        {/* This code is commented beacuse in future if we want to change the theme on switch button */}
        {/* <MenuItem>
          Light / Dark
          <ListItemIcon>
            <Switch checked={themeChange} onChange={handleSwitchChange} />
          </ListItemIcon>
        </MenuItem> */}
        <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
          <Select
            options={themeOptions}
            itemText="id"
            itemValue="name"
            onChange={(e) => handleSwitchChange(e)}
            label="select theme"
            value={themeName}
          />
        </FormControl>
      </Menu>
    </Box>
  );

  useEffect(() => {
    EventEmitter.addListener('apiLoaderEvent', function (state) {
      setLoader(state);
    });
    EventEmitter.addListener('logoutSession', function (state) {
      if (state) {
        dispatch(getLogOutUser(null));
        //wait for tost notification to popup and then redirect
        setTimeout(() => {
          Signout();
          resetLogoutSession();
        }, 1000);
      }
    });
  }, []);

  return (
    <>
      <Box className="api-loader-parent">
        {getLoader && (
          <LinearProgress
            sx={{ background: primaryColor }}
            className="api-loader"
          />
        )}
      </Box>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <AuthProvider>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                {content}
                {/* theme base UI might require in future */}
                {/* {configsButton}  */}
              </LocalizationProvider>
            </ThemeProvider>
          </StylesProvider>
        </AuthProvider>
      </PersistGate>
    </>
  );
};
export default App;
