import { FC, useState, createContext } from 'react';
import { SidebarContextType } from './models/SidebarContextType';
//import image from '../../../assets/images/indexnine.jpg';

import image from '../../src/assets/images/indexnine-logo.png';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContextType>(
  {} as SidebarContextType
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [appLogo, setAppLogo] = useState(image);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        appLogo,
        setAppLogo
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
