import { Config, apiGet, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getOrganizationDataAPI = async () => {
  //console.trace();
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet('/tenant/');
  return res.data;
};

export const getTenantUserOrganizationDataAPI = async (userId: string) => {
  const res = await apiGet(`/tenant/${userId}/organizations`);
  return res.data;
};

export const addTenantAPI = async (orgName: string) => {
  const res = await apiPost('/tenant/', {
    name: orgName
  });
  return res.data;
};

// Add Organization Image
export const addTenantImageAPI = async (formData: FormData) => {
  const res = await apiPost('/document/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

//Get Organization Image
export const getTenantImgeAPI = async (documentId: string) => {
  const res = await apiGet(`/document/image/${documentId}`);
  return res.data;
};

//Download Organization Image
export const downloadImage = async (documentId: string) => {
  const res = await apiGet(`/document/download/${documentId}`, {
    responseType: 'blob'
  });

  const blob = new Blob([res.data]);
  return URL.createObjectURL(blob);
  //return res.data;
};

export const updateTenantAPI = async (organization: any) => {
  const res = await apiPut('/tenant/' + organization.id, {
    name: organization.tenantName
  });
  return res;
};

export const updateOrganizationPermissionAPI = async (payload: any) => {
  const res = await apiPut(
    '/access/tenant/' + payload.orgId + '/role/' + payload.role,
    {
      value: payload.flag
    }
  );
  return res.data;
};
export const getTenantPermissionDetailsAPI = async (payload: any) => {
  const res = await apiGet(
    `access/tenant/${payload.tenantId}/role/${payload.role}`
  );
  return res.data;
};
