import { apiPost } from '../../../shared/utils/api-request';

export const submitAssessmentVideoAPI = async (userData: any) => {
  const res = await apiPost(
    `/user/${userData.user_id}/assessment/${userData.assessment_id}/topic/${userData.topic_id}/upload-video`,
    { video: userData.video_url },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return res.data;
};
