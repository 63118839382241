import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { submitAssessmentVideoAPI } from '../apis/RecordAssessmentApi';

const initialState: any = {
  loading: false,
  submitResponse: {}
};

export const submitVideo = createAsyncThunk(
  'recordAssessment/submitVideo',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await submitAssessmentVideoAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const recordAssessmentSlice = createSlice({
  name: 'recordAssessment',
  initialState,
  reducers: {
    setClearSubmitResponse: (state: any, action) => {
      state.submitResponse = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitVideo.fulfilled, (state: any, action) => {
        state.loading = false;
        if (action.payload) {
          state.submitResponse = action.payload;
        }
      })
      .addCase(submitVideo.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { setClearSubmitResponse } = recordAssessmentSlice.actions;
export default recordAssessmentSlice.reducer;
