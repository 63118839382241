import { Box, Typography } from '@mui/material';
import React from 'react';
import { Button } from '../../../shared/components/button/Button';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import RetakeAssessmentModalGirl from '../../../assets/illustrations/retake-assessment-modal-girl.svg';

interface RetakeAssessmentModalProps {
  handleClose?: () => void;
  handleOnRetakeAssessmentConfirm?: () => void;
}

const RetakeAssessmentModal = ({
  handleClose,
  handleOnRetakeAssessmentConfirm
}: RetakeAssessmentModalProps) => {
  return (
    <Box className="retake-assessment-modal-main-container">
      <Box className="retake-assessment-modal-heading-container">
        <Typography className="retake-assessment-modal-heading-text">
          Re-take assessment
        </Typography>
        <Button
          variant="contained"
          icon={<CloseTwoToneIcon />}
          bgColor="white"
          textColor="black"
          border="2px solid rgb(128, 128, 128, 0.5)"
          width="36px"
          height="36px"
          minWidth="36px"
          onClick={handleClose}
        ></Button>
      </Box>
      <Box className="retake-assessment-modal-body-container">
        <Box
          component="img"
          sx={{
            height: 130,
            width: 130
          }}
          src={RetakeAssessmentModalGirl}
        />
        <Typography className="retake-assessment-modal-body-text">
          Do you want to retake the assessment? If you choose to proceed, all
          previous reports will be deleted.
        </Typography>
      </Box>
      <Box className="retake-assessment-modal-buttons-container">
        <Button
          btnText="Cancel"
          variant="outlined"
          border="1px solid black"
          bgColor="white"
          textColor="black"
          minWidth="150px"
          onClick={handleClose}
        ></Button>
        <Button
          btnText="PROCEED"
          variant="contained"
          //   border="2px solid black"
          bgColor="rgba(151, 61, 231, 1)"
          textColor="white"
          minWidth="150px"
          style={{ marginLeft: '2rem' }}
          onClick={handleOnRetakeAssessmentConfirm}
        ></Button>
      </Box>
    </Box>
  );
};

export default RetakeAssessmentModal;
