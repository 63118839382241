import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

//To convert any string to title case
/**
 * @param value
 * @returns title case string
 */
export const titleCase = (value: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

//To convert date string in specific format, by default MM/DD/YYYY
/**
 * @param date
 * @param format optional
 * @returns formated date
 */
export const formatDate = (date: string, format?: string) => {
  return date && dayjs(date).isValid()
    ? dayjs(date).format(format ? format : 'MM/DD/YYYY')
    : '';
};

//To convert date string to UTC in specific format, by default MM/DD/YYYY
/**
 * @param date
 * @param format optional
 * @returns formated utc date
 */
export const formatDateToUtc = (date: string, format?: string) => {
  dayjs.extend(utc);
  return dayjs(date).isValid()
    ? dayjs(date)
        .utc(true)
        .format(format ? format : 'MM/DD/YYYY')
    : '';
};

//To extract time from date string in specific format, by default hh:mm a
/**
 * @param date
 * @param format optional
 * @returns formated time
 */
export const getTimeStamp = (date: string, format?: string) => {
  return dayjs(date).isValid()
    ? dayjs(date).format(format ? format : 'hh:mm a')
    : '';
};

//Get the initials of any string value
/**
 * @param text
 * @returns initials of given text
 */
export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

//Remove underscore from any string value
/**
 * @param text
 * @returns given string without underscore
 */
export const removeUnderscore = (text: string) => {
  return text
    ? text.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
      })
    : '';
};

//To conver bytes to kb
/**
 * @param value in bytes
 * @returns converted value in kb
 */
export const convertBytesToKb = (value: number) => {
  return value ? Math.round(value / Math.pow(1024, 1)) : 0;
};

//To get the today's date
/**
 * @returns current date in YYYY-MM-DD format
 */
export const getDate = () => {
  const today = new Date();
  const dayNum = today.getDate();
  const monthNum = today.getMonth() + 1;
  const yearNum = today.getFullYear();
  let day = dayNum.toString();
  let month = monthNum.toString();
  if (dayNum < 10) {
    day = '0' + dayNum;
  }

  if (monthNum < 10) {
    month = '0' + monthNum;
  }
  return `${yearNum}-${month}-${day}`;
};

//To convert hexa code to rgba format
/**
 * @param hex
 * @param opacity
 * @returns converted rgba value
 */
export const hexToRgbA = (hex: string, opacity: number) => {
  let colorData: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    colorData = hex.substring(1).split('');
    if (colorData.length == 3) {
      colorData = [
        colorData[0],
        colorData[0],
        colorData[1],
        colorData[1],
        colorData[2],
        colorData[2]
      ];
    }
    colorData = '0x' + colorData.join('');
    return (
      'rgba(' +
      [(colorData >> 16) & 255, (colorData >> 8) & 255, colorData & 255].join(
        ','
      ) +
      `,${opacity})`
    );
  }
  throw new Error('Bad Hex');
};

//To get the comma seperated values from array of string
/**
 * @param data
 * @returns Comma seperated string
 */
export const getCommaSeperatedValues = (data: string[]) => {
  return data?.length > 0 ? data?.toString()?.replaceAll(',', ', ') : '';
};

/**
 * @param color
 * @param percent
 * This function will return the shadeColor related to main color for secondary color
 */
export const shadeColor = (color: string, percent: number) => {
  let r: number = Number.parseInt(color.slice(1, 3), 16);
  let g: number = Number.parseInt(color.slice(3, 5), 16);
  let b: number = Number.parseInt(color.slice(5, 7), 16);

  r = (r * (100 + percent)) / 100;
  g = (g * (100 + percent)) / 100;
  b = (b * (100 + percent)) / 100;

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);

  const rr =
    r.toString(16).length === 1 ? '0' + r.toString(16) : r.toString(16);
  const gg =
    g.toString(16).length === 1 ? '0' + g.toString(16) : g.toString(16);
  const bb =
    b.toString(16).length === 1 ? '0' + b.toString(16) : b.toString(16);

  return '#' + rr + gg + bb;
};

type RGB = {
  r: number;
  g: number;
  b: number;
};

const convertHexToRgb = (color: string): RGB | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16)
      }
    : null;
};

export const getContrastTextColor = (color: string) => {
  const rgb = convertHexToRgb(color);
  if (!rgb) {
    return '#ffffff';
  }
  if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
    return '#000000';
  } else {
    return '#ffffff';
  }
};

/** We can use this to calculate the secondary color */
/* export function calculateSecondaryColor(primaryColor) {
  // Convert the hex color to RGB
  function hexToRgb(hex) {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return { r, g, b };
  }

  // Convert the RGB color back to hex
  function rgbToHex(r, g, b) {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  }

  // Calculate the secondary color by modifying the RGB values
  function adjustColor(r, g, b, adjustment) {
    return {
      r: Math.min(255, Math.max(0, r + adjustment)),
      g: Math.min(255, Math.max(0, g + adjustment)),
      b: Math.min(255, Math.max(0, b + adjustment))
    };
  }

  // Adjust each RGB component by a fixed amount (example: 20)
  const adjustment = 20;

  // Convert primary color to RGB
  const { r, g, b } = hexToRgb(primaryColor);

  // Adjust the color
  const { r: newR, g: newG, b: newB } = adjustColor(r, g, b, adjustment);

  // Convert back to hex
  return rgbToHex(newR, newG, newB);
} */
