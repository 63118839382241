import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonProps } from './services/buttonInterface';

const useStyles = makeStyles({
  iconOnly: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

export const Button = ({
  btnText,
  icon,
  minWidth,
  bgColor,
  textColor,
  border,
  width,
  height,
  btnClass,
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <>
      <MuiButton
        startIcon={icon}
        component="span"
        sx={{
          padding: btnText ? '6px 16px' : '6px',
          ...(minWidth && { minWidth: minWidth }),
          ...(bgColor && {
            backgroundColor: bgColor,
            '&:hover': { backgroundColor: bgColor, border: border }
          }),
          ...(textColor && { color: textColor }),
          ...(border && { border: border }),
          ...(width && { width: width }),
          ...(height && { height: height })
        }}
        className={btnText ? btnClass : classes.iconOnly}
        {...props}
      >
        {btnText}
      </MuiButton>
    </>
  );
};
