import { createSlice } from '@reduxjs/toolkit';
import { shadeColor } from 'src/shared/utils/utils';
import { themeColors } from 'src/shared/constants/constants';
import { StateI } from 'src/shared/utils/Interfaces';
import { RootState } from 'src/store/reducer';

type Action = {
  payload: {
    hex: string;
    mode: string;
  };
};
const initialState: StateI = themeColors;

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateTheme(state: StateI, action: Action) {
      state.primary = action.payload.hex;
      state.secondary = shadeColor(action.payload.hex, 20);
      state.black = shadeColor(action.payload.hex, -65);
    },
    updateMode(state: StateI, action: Action) {
      state.mode = action.payload.mode;
    }
  }
});
export const { updateTheme, updateMode } = themeSlice.actions;
export default themeSlice.reducer;
