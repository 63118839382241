export const LOGIN = '/';
export const SET_PASSWORD = '/set-password';
export const FORGET_PASSWORD = '/forget-password';
export const ADMIN_FORGET_PASSWORD = '/admin/forget-password';
export const CHECK_YOUR_EMAIL = '/check-your-email';
export const LANDING_PAGE = '/not-found';

export const ADMIN_LOGIN = '/admin';
export const TENANT_LOGIN = '/tenant';

export const DASHBOARD = '/dashboards';

export const ORGANIZATION = '/organization';
export const ADMIN_MANAGEMENT = '/admin-management';

export const USER_MANAGEMENT = '/user-management';
export const ASSESSMENT = '/assessment';
export const RECORD_ASSESSMENT = '/record-assessment';
export const REPORTS = '/reports';
export const SETTINGS = '/settings';

export const TENANT_LANDING_PAGE_WITHOUT_USER =
  '/tenant/${tenant_id}/unit/${unit_id}';

export const SHOW_ALL_ASSESSMENTS = '/show-all-assessment';
export const LOGIN_WITHOUT_ANY_ID = '/login';
