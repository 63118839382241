import { alpha, createTheme, darken, lighten } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { shadeColor } from 'src/shared/utils/utils';

export interface ThemeParams {
  primaryColor: string;
  secondaryColor?: string;
}

export const createCustomTheme = ({ primaryColor }: ThemeParams): Theme => {
  const themeColors = {
    primary: primaryColor,
    secondary: shadeColor(primaryColor, 10),
    success: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: shadeColor(primaryColor, -65),
    white: '#ffffff',
    primaryAlt: '#000C57'
  };

  const colors = {
    gradients: {
      blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
      blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
      blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
      blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
      blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
      orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
      orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
      orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
      purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
      purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
      pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
      pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
      green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
      green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
      black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
      black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
    },
    shadows: {
      success:
        '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
      error:
        '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
      info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
      primary:
        '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
      warning:
        '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
      card: '0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
      cardSm:
        '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
      cardLg:
        '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)'
    },
    layout: {
      general: {
        bodyBg: '#f2f5f9'
      },
      sidebar: {
        background: themeColors.white,
        textColor: themeColors.secondary,
        dividerBg: '#f2f5f9',
        menuItemColor: '#242E6F',
        menuItemColorActive: themeColors.primary,
        menuItemBg: themeColors.white,
        menuItemBgActive: '#f2f5f9',
        menuItemIconColor: lighten(themeColors.secondary, 0.3),
        menuItemIconColorActive: themeColors.primary,
        menuItemHeadingColor: darken(themeColors.secondary, 0.3)
      }
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      trueWhite: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black
      }
    },
    secondary: {
      lighter: lighten(themeColors.secondary, 0.85),
      light: lighten(themeColors.secondary, 0.25),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2)
    },
    primary: {
      lighter: lighten(themeColors.primary, 0.85),
      light: lighten(themeColors.primary, 0.3),
      main: themeColors.primary,
      dark: darken(themeColors.primary, 0.2)
    },
    success: {
      lighter: lighten(themeColors.success, 0.85),
      light: lighten(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      lighter: lighten(themeColors.warning, 0.85),
      light: lighten(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      lighter: lighten(themeColors.error, 0.85),
      light: lighten(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      lighter: lighten(themeColors.info, 0.85),
      light: lighten(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    }
  };

  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: colors.primary.light,
        main: colors.primary.main,
        dark: colors.primary.dark
      },
      secondary: {
        light: colors.secondary.light,
        main: colors.secondary.main,
        dark: colors.secondary.dark
      },
      error: {
        light: colors.error.light,
        main: colors.error.main,
        dark: colors.error.dark,
        contrastText: colors.alpha.white[100]
      },
      success: {
        light: colors.success.light,
        main: colors.success.main,
        dark: colors.success.dark,
        contrastText: colors.alpha.white[100]
      },
      info: {
        light: colors.info.light,
        main: colors.info.main,
        dark: colors.info.dark,
        contrastText: colors.alpha.white[100]
      },
      warning: {
        light: colors.warning.light,
        main: colors.warning.main,
        dark: colors.warning.dark,
        contrastText: colors.alpha.white[100]
      },
      text: {
        primary: colors.alpha.black[100],
        secondary: colors.alpha.black[70],
        disabled: colors.alpha.black[50]
      },
      background: {
        paper: colors.alpha.white[100],
        default: colors.layout.general.bodyBg
      },
      action: {
        active: colors.alpha.black[100],
        hover: colors.primary.lighter,
        hoverOpacity: 0.1,
        selected: colors.alpha.black[10],
        selectedOpacity: 0.1,
        disabled: colors.alpha.black[50],
        disabledBackground: colors.alpha.black[5],
        disabledOpacity: 0.38,
        focus: colors.alpha.black[10],
        focusOpacity: 0.05,
        activatedOpacity: 0.12
      },
      tonalOffset: 0.5
    },
    colors: {
      gradients: {
        blue1: colors.gradients.blue1,
        blue2: colors.gradients.blue2,
        blue3: colors.gradients.blue3,
        blue4: colors.gradients.blue4,
        blue5: colors.gradients.blue5,
        orange1: colors.gradients.orange1,
        orange2: colors.gradients.orange2,
        orange3: colors.gradients.orange3,
        purple1: colors.gradients.purple1,
        purple3: colors.gradients.purple3,
        pink1: colors.gradients.pink1,
        pink2: colors.gradients.pink2,
        green1: colors.gradients.green1,
        green2: colors.gradients.green2,
        black1: colors.gradients.black1,
        black2: colors.gradients.black2
      },
      shadows: {
        success: colors.shadows.success,
        error: colors.shadows.error,
        primary: colors.shadows.primary,
        info: colors.shadows.info,
        warning: colors.shadows.warning
      },
      alpha: {
        white: {
          5: alpha(themeColors.white, 0.02),
          10: alpha(themeColors.white, 0.1),
          30: alpha(themeColors.white, 0.3),
          50: alpha(themeColors.white, 0.5),
          70: alpha(themeColors.white, 0.7),
          100: themeColors.white
        },
        trueWhite: {
          5: alpha(themeColors.white, 0.02),
          10: alpha(themeColors.white, 0.1),
          30: alpha(themeColors.white, 0.3),
          50: alpha(themeColors.white, 0.5),
          70: alpha(themeColors.white, 0.7),
          100: themeColors.white
        },
        black: {
          5: alpha(themeColors.black, 0.02),
          10: alpha(themeColors.black, 0.1),
          30: alpha(themeColors.black, 0.3),
          50: alpha(themeColors.black, 0.5),
          70: alpha(themeColors.black, 0.7),
          100: themeColors.black
        }
      },
      secondary: {
        lighter: alpha(themeColors.secondary, 0.1),
        light: lighten(themeColors.secondary, 0.3),
        main: themeColors.secondary,
        dark: darken(themeColors.secondary, 0.2)
      },
      primary: {
        lighter: alpha(themeColors.primary, 0.1),
        light: lighten(themeColors.primary, 0.3),
        main: themeColors.primary,
        dark: darken(themeColors.primary, 0.2)
      },
      success: {
        lighter: alpha(themeColors.success, 0.1),
        light: lighten(themeColors.success, 0.3),
        main: themeColors.success,
        dark: darken(themeColors.success, 0.2)
      },
      warning: {
        lighter: alpha(themeColors.warning, 0.1),
        light: lighten(themeColors.warning, 0.3),
        main: themeColors.warning,
        dark: darken(themeColors.warning, 0.2)
      },
      error: {
        lighter: alpha(themeColors.error, 0.1),
        light: lighten(themeColors.error, 0.3),
        main: themeColors.error,
        dark: darken(themeColors.error, 0.2)
      },
      info: {
        lighter: alpha(themeColors.info, 0.1),
        light: lighten(themeColors.info, 0.3),
        main: themeColors.info,
        dark: darken(themeColors.info, 0.2)
      }
    },
    general: {
      reactFrameworkColor: '#00D8FF',
      borderRadiusSm: '6px',
      borderRadius: '10px',
      borderRadiusLg: '12px',
      borderRadiusXl: '16px',
      deleteModalIcon: '#434469',
      deleteModalIconBackgroundBolor: 'rgba(169, 169, 209, 0.4)'
    },
    sidebar: {
      background: colors.layout.sidebar.background,
      textColor: colors.layout.sidebar.textColor,
      dividerBg: colors.layout.sidebar.dividerBg,
      menuItemColor: colors.layout.sidebar.menuItemColor,
      menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
      menuItemBg: colors.layout.sidebar.menuItemBg,
      menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
      menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
      menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
      menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
      boxShadow:
        '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)',
      width: '252px'
    },
    spacing: 7,
    header: {
      height: '62px',
      background: colors.alpha.white[100],
      boxShadow: colors.shadows.cardSm,
      textColor: colors.secondary.main
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1840
      }
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(darken(themeColors.primaryAlt, 0.4), 0.2),
            //to hide modal blur effect
            // backdropFilter: 'blur(2px)',

            '&.MuiBackdrop-invisible': {
              backgroundColor: 'transparent'
              // backdropFilter: 'blur(2px)'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            marginLeft: 8,
            marginRight: 8,
            fontWeight: 'bold'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          'html, body': {
            width: '100%',
            height: '100%'
          },
          body: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            flex: 1
          },
          '#root': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
          },
          html: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            MozOsxFontSmoothing: 'grayscale'
          },
          '.child-popover .MuiPaper-root .MuiList-root': {
            flexDirection: 'column'
          },
          '#nprogress': {
            pointerEvents: 'none'
          },
          '#nprogress .bar': {
            background: colors.primary.lighter
          },
          '#nprogress .spinner-icon': {
            borderTopColor: colors.primary.lighter,
            borderLeftColor: colors.primary.lighter
          },
          '#nprogress .peg': {
            boxShadow:
              '0 0 15px ' +
              colors.primary.lighter +
              ', 0 0 8px' +
              colors.primary.light
          },
          ':root': {
            '--swiper-theme-color': colors.primary.main
          },
          code: {
            background: colors.info.lighter,
            color: colors.info.dark,
            borderRadius: 4,
            padding: 4
          },
          '@keyframes ripple': {
            '0%': {
              transform: 'scale(.8)',
              opacity: 1
            },
            '100%': {
              transform: 'scale(2.8)',
              opacity: 0
            }
          },
          '@keyframes float': {
            '0%': {
              transform: 'translate(0%, 0%)'
            },
            '100%': {
              transform: 'translate(3%, 3%)'
            }
          },
          '&.MenuUserBox': {
            background: colors.alpha.black[5],
            padding: 14,
            display: 'flex'
          },
          '&.UserBoxText': {
            textalign: 'left',
            paddingLeft: 7
          },
          '&.SidebarWrapper': {
            width: '252px',
            minWidth: '252px',
            color: themeColors.secondary,
            position: 'relative',
            zIndex: 7,
            height: '100%',
            paddingBotton: '68px'
          },
          '&.SidebarWrapperDarkTheme': {
            background: colors.layout.sidebar.background
          },
          '&.SidebarWrapperlightTheme': {
            background: colors.alpha.white[100]
          },
          '&.PageTitle': {
            padding: 28
          },
          '&.HeaderWrapper': {
            height: '62px',
            color: colors.secondary.main,
            padding: 14,
            right: 0,
            zIndex: 6,
            backgroundColor: colors.alpha.white[100],
            // backdropFilter: 'blur 3px',
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%'
          },
          '&.HeaderWrapperDarkTheme': {
            boxShadow: `0 1px 0 ${alpha(
              lighten(colors.primary.main, 0.7),
              0.15
            )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
          },
          '&.HeaderWrapperLightTheme': {
            boxShadow: `0px 2px 8px -3px ${alpha(
              colors.alpha.black[100],
              0.2
            )}, 0px 5px 22px -4px ${alpha(colors.alpha.black[100], 0.1)}`
          },
          '&.listWrapper': {
            '.MuiListItem-root': {
              transition: 'all .2s',
              '&.MuiListItem-indicators': {
                padding: '4px 8px',
                '.MuiListItemText-root': {
                  '.MuiTypography-root': {
                    '&::before': {
                      height: '4px',
                      width: '22px',
                      opacity: 0,
                      visibility: 'hidden',
                      display: 'block',
                      position: 'absolute',
                      bottom: '-10px',
                      transition: 'all .2s',
                      borderRadius: '10px',
                      content: '""',
                      background: colors.primary.main
                    }
                  }
                },
                '&.active &:active &:hover': {
                  background: 'transparent',
                  '.MuiListItemText-root': {
                    '.MuiTypography-root': {
                      '&:before': {
                        opacity: 1,
                        visibility: 'visible',
                        bottom: '0px'
                      }
                    }
                  }
                }
              }
            }
          },
          '&.TextWrapper': {
            display: 'inline-block',
            alignItems: 'center',
            '&.flexItem': {
              display: 'inline-flex'
            },
            '&.MuiText': {
              '&-black': {
                color: colors.alpha.black
              },

              '&-primary': {
                color: colors.primary.main
              },

              '&-secondary': {
                color: colors.secondary.main
              },

              '&-success': {
                color: colors.success.main
              },

              '&-warning': {
                color: colors.warning.main
              },

              '&-error': {
                color: colors.error.main
              },

              '&-info': {
                color: colors.info.main
              }
            }
          },
          '&.LabelWrapper': {
            backgroundColor: colors.alpha.black[5],
            padding: '3.5px 7px',
            fontSize: '0.813rem',
            borderRadius: '10px',
            display: ' inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: 21,
            '  &.MuiLabel': {
              ' &-primary': {
                backgroundColor: colors.primary.lighter,
                color: colors.primary.main
              },

              ' &-black': {
                backgroundColor: colors.alpha.black[100],
                color: colors.alpha.white[100]
              },

              ' &-secondary': {
                backgroundColor: colors.secondary.lighter,
                color: colors.secondary.main
              },

              ' &-success ': {
                backgroundColor: colors.success.lighter,
                color: colors.success.main
              },

              '&-warning': {
                backgroundColor: colors.warning.lighter,
                color: colors.warning.main
              },

              ' &-error': {
                backgroundColor: colors.error.lighter,
                color: colors.error.main
              },

              ' &-info': {
                backgroundColor: colors.info.lighter,
                color: colors.info.main
              }
            }
          },
          '&.Scrollbar': {
            width: 5,
            background: colors.alpha.black[10],
            borderRadius: '12px',
            transition: 'all .2s',

            '&:hover': {
              background: colors.alpha.black[30]
            }
          },
          '&.MenuWrapper': {
            '.MuiList-root': {
              padding: 7,

              ' & > .MuiList-root ': {
                padding: '0px 0px 7px'
              },
              '.MuiListSubheader-root': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                color: themeColors.secondary,
                padding: '0px 17.5px',
                lineHeight: 1.4
              }
            }
          },

          '&.MuiPageTitlewrapperLight': {
            '.MuiPageTitle-wrapper': {
              background: colors.alpha.white[50],
              marginBottom: 28,
              boxShadow: `0px 2px 4px -3px ${alpha(
                colors.alpha.black[100],
                0.1
              )}, 0px 5px 12px -4px ${alpha(colors.alpha.black[100], 0.05)}`
            }
          },
          '&.sidebarwrapperLight': {
            position: 'fixed',
            left: 0,
            top: 0,
            background: alpha(lighten(colors.alpha.white[100], 0.1), 0.5),
            boxShadow:
              ' 2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)'
          },
          '&.sidebarDrawer': {
            boxShadow:
              '2px 0 3px rgba(159, 162, 191, .18), 1px 0 1px rgba(159, 162, 191, 0.32)'
          },
          '&.SubMenuWrapper': {
            '.MuiList-root': {
              '.MuiListItem-root': {
                padding: ' 1px 0',

                ' .MuiBadge-root': {
                  position: 'absolute',
                  right: '3.2px',

                  '.MuiBadge-standard': {
                    background: colors.layout.sidebar.background,
                    fontSize: '0.625rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: colors.primary.dark
                  }
                },

                '.MuiButton-root': {
                  display: 'flex',
                  color: colors.alpha.black[70],
                  backgroundColor: colors.alpha.black,
                  width: ' 100%',
                  justifyContent: ' flex-start',
                  padding: '8.4px 21px',
                  '&.active, &:hover': {
                    backgroundColor: alpha(colors.alpha.black[100], 0.06),
                    color: colors.layout.sidebar.textColor,

                    '.MuiButton-endIcon': {
                      color: colors.layout.sidebar.textColor
                    }
                  },

                  '.MuiButton-endIcon ': {
                    transition: 'color .2s',
                    '.MuiSvgIcon-root': {
                      fontSize: 'inherit',
                      transition: 'none'
                    }
                  },

                  '.MuiButton-startIcon ': {
                    color: colors.layout.sidebar.textColor,
                    fontSize: '1.25rem',
                    marginRight: 7
                  },

                  '.MuiButton-endIcon': {
                    color: colors.alpha.trueWhite[50],
                    marginLeft: 'auto',
                    opacity: '.8',
                    fontSize: '1.25rem'
                  }
                },

                ' &.Mui-children ': {
                  flexDirection: 'column',

                  '.MuiBadge-root': {
                    position: 'absolute',
                    right: 7
                  }
                },

                ' .MuiCollapse-root': {
                  width: '100%',

                  '.MuiList-root': {
                    padding: '7px 0'
                  },

                  ' .MuiListItem-root': {
                    padding: '1px 0',

                    '.MuiButton-root': {
                      padding: '5.6px 21px ',

                      '.MuiBadge-root': {
                        right: 3.2
                      },

                      '&:before': {
                        background: colors.layout.sidebar.background,
                        opacity: 0,
                        transition: 'opacity',

                        width: '6px',
                        height: '6px',
                        transform: 'scale(0)',
                        transformOrigin: 'center',
                        borderRadius: '20px',
                        marginRight: 1.8
                      },

                      ' &.active, &:hover ': {
                        ' &:before': {
                          transform: 'scale(1)',
                          opacity: '1'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          iconOutlined: {
            color: colors.alpha.black[50]
          },
          icon: {
            top: 'calc(50% - 14px)'
          }
        }
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
              paddingRight: 6
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.alpha.black[50]
            },
            '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.primary.main
            }
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          colorPrimary: {
            fontWeight: 'bold',
            lineHeight: '40px',
            fontSize: '0.813rem',
            background: colors.alpha.black[5],
            color: colors.alpha.black[70]
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          action: {
            marginTop: -5,
            marginBottom: -5
          },
          title: {
            fontSize: '0.938rem'
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            borderRadius: '50px'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          colorSecondary: {
            background: colors.alpha.black[5],
            color: colors.alpha.black[100],

            '&:hover': {
              background: colors.alpha.black[10]
            }
          },
          deleteIcon: {
            color: colors.error.light,

            '&:hover': {
              color: colors.error.main
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',

            '&.Mui-expanded': {
              margin: 0
            },
            '&::before': {
              display: 'none'
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            fontWeight: 'bold',

            '&.SearchAvatar': {
              background: colors.secondary.main
            }
          },
          colorDefault: {
            background: colors.alpha.black[30],
            color: colors.alpha.white[100]
          }
        }
      },
      MuiAvatarGroup: {
        styleOverrides: {
          root: {
            alignItems: 'center'
          },
          avatar: {
            background: colors.alpha.black[10],
            fontSize: '0.813rem',
            color: colors.alpha.black[70],
            fontWeight: 'bold',

            '&:first-of-type': {
              border: 0,
              background: 'transparent'
            }
          }
        }
      },
      MuiListItemAvatar: {
        styleOverrides: {
          alignItemsFlexStart: {
            marginTop: 0
          }
        }
      },
      MuiPaginationItem: {
        styleOverrides: {
          page: {
            fontSize: '0.813rem',
            fontWeight: 'bold',
            transition: 'all .2s'
          },
          textPrimary: {
            '&.Mui-selected': {
              boxShadow: colors.shadows.primary
            },
            '&.MuiButtonBase-root:hover': {
              background: colors.alpha.black[5]
            },
            '&.Mui-selected.MuiButtonBase-root:hover': {
              background: colors.primary.main
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '&.DialogTitle-Wrapper': {
              background: colors.alpha.black[5],
              padding: 7
            }
          }
        }
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            '.MuiTypography-gutterBottom': {
              paddingTop: '4px'
            }
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            '&.MuiBadge-badge': {
              color: colors.primary.main,
              width: '22px',
              height: '16px',
              padding: 0,
              '&:after': {
                position: 'absolute',
                backgroundColor: alpha(colors.primary.main, 0.3),
                top: '-7px',
                left: '10px',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                boxShadow: '0 0 0 1px ' + alpha(colors.primary.main, 0.3),
                content: '""'
              }
            }
          }
        }
      },

      MuiButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            textTransform: 'none',
            paddingLeft: 16,
            paddingRight: 16,

            '.MuiSvgIcon-root': {
              transition: 'all .2s'
            },
            '&.errorbutton': {
              backgroundColor: colors.error.main,
              color: colors.alpha.white[100],
              border: '1px solid ' + colors.alpha.black[30],
              ' &:hover': {
                background: colors.error.dark
              }
            }
          },
          endIcon: {
            marginRight: -8
          },
          containedSecondary: {
            backgroundColor: colors.secondary.main,
            color: colors.alpha.white[100],
            border: '1px solid ' + colors.alpha.black[30]
          },
          outlinedSecondary: {
            backgroundColor: colors.alpha.white[100],

            '&:hover, &.MuiSelected': {
              backgroundColor: colors.alpha.black[5],
              color: colors.alpha.black[100]
            }
          },
          sizeSmall: {
            padding: '6px 16px',
            lineHeight: 1.5
          },
          sizeMedium: {
            padding: '8px 20px'
          },
          sizeLarge: {
            padding: '11px 24px'
          },
          textSizeSmall: {
            padding: '7px 12px'
          },
          textSizeMedium: {
            padding: '9px 16px'
          },
          textSizeLarge: {
            padding: '12px 16px'
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false
        },
        styleOverrides: {
          root: {
            borderRadius: 6
          }
        }
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: colors.primary.main,
            background: colors.alpha.white[100],
            transition: 'all .2s',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: colors.alpha.white[100],
              background: colors.primary.main
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: 8,

            '& .MuiTouchRipple-root': {
              borderRadius: 8
            },
            '&.RecentOrderEditButton': {
              '&:hover': {
                background: colors.primary.lighter
              },
              color: colors.primary.main
            },
            '&.RecentOrderDeleteButton': {
              '&:hover': {
                background: colors.error.lighter
              },
              color: colors.error.main
            }
          },
          sizeSmall: {
            padding: 4
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 0
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '& .MuiTouchRipple-root': {
              opacity: 0.3
            }
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            background: colors.alpha.black[10],
            border: 0,
            height: 1,
            '&.sidebarDividerbg': {
              background: '#f2f5f9'
            }
          },
          vertical: {
            height: 'auto',
            width: 1,

            '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
              height: 'auto'
            },
            '&.MuiDivider-absolute.MuiDivider-fullWidth': {
              height: '100%'
            }
          },
          withChildren: {
            '&:before, &:after': {
              border: 0
            }
          },
          wrapper: {
            background: colors.alpha.white[100],
            fontWeight: 'bold',
            height: 24,
            lineHeight: '24px',
            marginTop: -12,
            color: 'inherit',
            textTransform: 'uppercase'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: 0
          },
          elevation0: {
            boxShadow: 'none'
          },
          elevation: {
            boxShadow: colors.shadows.card
          },
          elevation2: {
            boxShadow: colors.shadows.cardSm
          },
          elevation24: {
            boxShadow: colors.shadows.cardLg
          },
          outlined: {
            boxShadow: colors.shadows.card
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            height: 6
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
              transform: 'none'
            },
            '& .MuiSlider-valueLabel': {
              borderRadius: 6,
              background: colors.alpha.black[100],
              color: colors.alpha.white[100]
            }
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,

            '& .MuiListItem-button': {
              transition: 'all .2s',

              '& > .MuiSvgIcon-root': {
                minWidth: 34
              },

              '& .MuiTouchRipple-root': {
                opacity: 0.2
              }
            },
            '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
              backgroundColor: alpha(colors.primary.lighter, 0.4)
            },
            '& .MuiMenuItem-root.MuiButtonBase-root:active': {
              backgroundColor: alpha(colors.primary.lighter, 0.4)
            },
            '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
              opacity: 0.2
            }
          },
          padding: {
            padding: '12px',

            '& .MuiListItem-button': {
              borderRadius: 6,
              margin: '1px 0'
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            height: 38,
            minHeight: 38,
            overflow: 'visible'
          },
          indicator: {
            height: 38,
            minHeight: 38,
            borderRadius: 6,
            border: '1px solid ' + colors.primary.dark,
            boxShadow: '0px 2px 10px ' + colors.primary.light
          },
          scrollableX: {
            overflow: 'visible !important'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            height: 38,
            minHeight: 38,
            borderRadius: 6,
            transition: 'color .2s',
            textTransform: 'capitalize',

            '&.MuiButtonBase-root': {
              minWidth: 'auto',
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 4
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              color: colors.alpha.white[100],
              zIndex: 5
            },
            '&:hover': {
              color: colors.alpha.black[100]
            }
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 12
          },
          list: {
            padding: 12,

            '& .MuiMenuItem-root.MuiButtonBase-root': {
              fontSize: '0.875rem',
              marginTop: 1,
              marginBottom: 1,
              transition: 'all .2s',
              color: colors.alpha.black[70],

              '& .MuiTouchRipple-root': {
                opacity: 0.2
              },

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: colors.alpha.black[100],
                background: alpha(colors.primary.lighter, 0.4)
              }
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: 'transparent',
            transition: 'all .2s',

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: alpha(colors.primary.lighter, 0.4)
            },
            '&.Mui-selected:hover': {
              background: alpha(colors.primary.lighter, 0.4)
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root': {
              color: colors.secondary.main,

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: colors.alpha.black[100],
                background: lighten(colors.primary.lighter, 0.5)
              }
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            margin: 1
          },
          root: {
            '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
              {
                right: 14
              }
          },
          clearIndicator: {
            background: colors.error.lighter,
            color: colors.error.main,
            marginRight: 8,

            '&:hover': {
              background: colors.error.lighter,
              color: colors.error.dark
            }
          },
          popupIndicator: {
            color: colors.alpha.black[50],

            '&:hover': {
              background: colors.primary.lighter,
              color: colors.primary.main
            }
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            '& .MuiIconButton-root': {
              padding: 8
            }
          },
          select: {
            '&:focus': {
              backgroundColor: 'transparent'
            }
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '0 !important',
            padding: '0 !important'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            background: colors.alpha.black[5]
          },
          root: {
            transition: 'background-color .2s',

            '&.MuiTableRow-hover:hover': {
              backgroundColor: colors.alpha.black[5]
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottomColor: colors.alpha.black[10],
            fontSize: '0.875rem'
          },
          head: {
            textTransform: 'uppercase',
            fontSize: '0.813rem',
            fontWeight: 'bold',
            color: colors.alpha.black[70]
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          message: {
            lineHeight: 1.5,
            fontSize: '0.875rem'
          },
          standardInfo: {
            color: colors.info.main
          },
          action: {
            color: colors.alpha.black[70]
          }
        }
      },
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            margin: 0,
            zIndex: 5,
            position: 'absolute',
            top: '50%',
            marginTop: -6,
            left: -6
          },
          outlined: {
            backgroundColor: colors.alpha.white[100],
            boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
          },
          outlinedPrimary: {
            backgroundColor: colors.alpha.white[100],
            boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
          }
        }
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            position: 'absolute',
            height: '100%',
            top: 0,
            borderRadius: 50,
            backgroundColor: colors.alpha.black[10]
          }
        }
      },
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: 0,
            padding: '8px 0',

            '&:before': {
              display: 'none'
            }
          },
          missingOppositeContent: {
            '&:before': {
              display: 'none'
            }
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: alpha(colors.alpha.black['100'], 0.95),
            padding: '8px 16px',
            fontSize: '0.813rem'
          },
          arrow: {
            color: alpha(colors.alpha.black['100'], 0.95)
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 33,
            overflow: 'visible',

            '& .MuiButtonBase-root': {
              position: 'absolute',
              padding: 6,
              transition:
                'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            },
            '& .MuiIconButton-root': {
              borderRadius: 100
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              opacity: 0.3
            }
          },
          thumb: {
            border: '1px solid ' + colors.alpha.black[30],
            boxShadow:
              '0px 9px 14px ' +
              colors.alpha.black[10] +
              ', 0px 2px 2px ' +
              colors.alpha.black[10]
          },
          track: {
            backgroundColor: colors.alpha.black[5],
            border: '1px solid ' + colors.alpha.black[10],
            boxShadow: 'inset 0px 1px 1px ' + colors.alpha.black[10],
            opacity: 1
          },
          colorPrimary: {
            '& .MuiSwitch-thumb': {
              backgroundColor: colors.alpha.white[100]
            },

            '&.Mui-checked .MuiSwitch-thumb': {
              backgroundColor: colors.primary.main
            }
          }
        }
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingTop: 20,
            paddingBottom: 20,
            background: colors.alpha.black[5]
          }
        }
      },

      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.MuiStepIcon-completed': {
              color: colors.success.main
            }
          }
        }
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'div',
            h4: 'div',
            h5: 'div',
            h6: 'div',
            subtitle1: 'div',
            subtitle2: 'div',
            body1: 'div',
            body2: 'div'
          }
        },
        styleOverrides: {
          root: {
            '&.UserBoxLabel': {
              fontWeight: 700,
              color: colors.secondary.main,
              display: 'block'
            },
            '&.UserBoxDescription': {
              color: lighten(colors.secondary.main, 0.5)
            },
            '&.SearchTypography': {
              color: lighten(colors.secondary.main, 0.5)
            },
            '&.RecentOrderTypography': {
              fontSize: '0.875rem'
            }
          },
          gutterBottom: {
            marginBottom: 4
          },
          paragraph: {
            fontSize: '1.063rem',
            lineHeight: 1.7
          }
        }
      }
    },
    shape: {
      borderRadius: 10
    },
    typography: {
      fontFamily:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      h1: {
        fontWeight: 700,
        fontSize: '2.188rem'
      },
      h2: {
        fontWeight: 700,
        fontSize: '1.875rem'
      },
      h3: {
        fontWeight: 700,
        fontSize: '1.2rem',
        lineHeight: 1.2,
        color: colors.alpha.black[100]
      },
      h4: {
        fontWeight: 700,
        fontSize: '1rem'
      },
      h5: {
        fontWeight: 700,
        fontSize: '0.85rem'
      },
      h6: {
        fontSize: '0.7rem'
      },
      body1: {
        fontSize: '0.875rem'
      },
      body2: {
        fontSize: '0.875rem'
      },
      button: {
        fontWeight: 600
      },
      caption: {
        fontSize: '0.813rem',
        textTransform: 'uppercase',
        color: colors.alpha.black[50]
      },
      subtitle1: {
        fontSize: '0.875rem',
        color: colors.alpha.black[70]
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: '0.938rem',
        color: colors.alpha.black[70]
      },
      overline: {
        fontSize: '0.813rem',
        fontWeight: 700,
        textTransform: 'uppercase'
      }
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none'
    ]
  });
};
