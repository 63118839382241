import { RootState } from '../../../store/reducer';
import { AssessmentData } from '../models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAssessmentQuestionsListAPI } from '../apis/AssessmentApi';
import { Action } from 'history';
import { retakeAssessmentAPI } from '../apis/RetakeAssessmentAPI';

interface AssessmentState {
  assessmentData: AssessmentData[];
  assessments: any;
  unit: any;
  loadingAssessmentData: boolean;
  isAssessmentListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  isDetailsAndGuideLineModalOpened: boolean;
  retakeAssessmentLoading: boolean;
  retakeAssessmentClicked: boolean;
}

const initialState: AssessmentState = {
  assessmentData: [],
  assessments: null,
  unit: null,
  loadingAssessmentData: false,
  isAssessmentListUpdated: false,
  isError: false,
  errorMessage: '',
  isDetailsAndGuideLineModalOpened: false,
  retakeAssessmentLoading: false,
  retakeAssessmentClicked: false
};

export const selectAssessmentState = ({ assessmentsData }: RootState) => ({
  assessments: assessmentsData?.assessments,
  assessmentData: assessmentsData?.assessmentData,
  unit: assessmentsData?.unit,
  loadingAssessmentData: assessmentsData?.loadingAssessmentData,
  isAssessmentListUpdated: assessmentsData?.isAssessmentListUpdated,
  isError: assessmentsData?.isError,
  errorMessage: assessmentsData?.errorMessage,
  isDetailsAndGuideLineModalOpened:
    assessmentsData?.isDetailsAndGuideLineModalOpened,
  retakeAssessmentClicked: assessmentsData?.retakeAssessmentClicked
});

export const getAssessmentQuestionsList = createAsyncThunk(
  'assessmentData/getAssessmentQuestionsList',
  async (
    payload: {
      tenantId: string;
      unitId: string;
      userId;
      isVerifiedUser?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getAssessmentQuestionsListAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const retakeAssessment = createAsyncThunk(
  'assessmentData/retakeAssessment',
  async (
    payload: { user_id: string; unit_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await retakeAssessmentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assessmentSlice = createSlice({
  name: 'assessmentData',
  initialState,
  reducers: {
    setIsAssessmentListUpdated: (state: any, action) => {
      state.isAssessmentListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setAssessment: (state: any, action) => {
      state.assessments = action?.payload;
      state.unit = action?.payload?.unit;
      state.assessmentData = action?.payload?.job_list?.sort(
        (a, b) => a?.topic?.serial_number - b?.topic?.serial_number
      );
    },
    setIsDetailsAndGuideLineModalOpened: (state: any, action) => {
      state.isDetailsAndGuideLineModalOpened = action.payload ?? true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentQuestionsList.pending, (state) => {
        state.loadingAssessmentData = true;
        state.assessmentData = [];
      })
      .addCase(getAssessmentQuestionsList.fulfilled, (state, action) => {
        state.loadingAssessmentData = false;
        if (action.payload) {
          state.assessments = action?.payload;
          state.unit = action?.payload?.unit;
          state.assessmentData = action?.payload?.job_list?.sort(
            (a, b) => a?.topic?.serial_number - b?.topic?.serial_number
          );
        }
        if (state.retakeAssessmentClicked) {
          state.retakeAssessmentClicked = false;
        }
      })
      .addCase(getAssessmentQuestionsList.rejected, (state, action: any) => {
        state.loadingAssessmentData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(retakeAssessment.pending, (state) => {
        state.retakeAssessmentLoading = true;
      })
      .addCase(retakeAssessment.fulfilled, (state, action) => {
        state.loadingAssessmentData = false;
        state.retakeAssessmentClicked = true;
      })
      .addCase(retakeAssessment.rejected, (state, action: any) => {
        state.loadingAssessmentData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});
export const {
  setIsDetailsAndGuideLineModalOpened,
  setIsError,
  setAssessment
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
