import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { I18N } from 'src/shared/constants/constants';

const useStyles = makeStyles({
  datatable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  }
});
export const Tables = (props) => {
  const { rows, columns, checkboxSelection = true } = props;
  const { t } = useTranslation(I18N.DEFAULT);

  const classes = useStyles();
  return (
    <div className="tablestyle">
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={false}
        pageSizeOptions={[15, 50, 100]}
        disableColumnMenu
        className={'table'}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        classes={{ root: classes.datatable }}
        localeText={{ noRowsLabel: t('table.noResultFound') }}
      />
    </div>
  );
};
