import DialogTitle from '@mui/material/DialogTitle';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  useTheme
} from '@mui/material';
import { CustomDialogProps } from './services/modalInterface';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

export const SimpleDialog = ({ ...props }: CustomDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const {
    onClose,
    selectedValue,
    open,
    model_title,
    model_content,
    model_actions,
    customClass,
    maxWidth
  } = props;
  const [modalMaxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(
    maxWidth ?? 'sm'
  );

  const handleClose = () => {
    onClose(selectedValue, 'backdropClick');
  };

  return (
    <Dialog
      BackdropProps={{
        style: {
          backdropFilter: 'blur(1px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
      }}
      fullWidth={fullWidth}
      maxWidth={modalMaxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      data-testid="modalcomponent"
      className={customClass}
    >
      {model_title && <DialogTitle>{model_title}</DialogTitle>}
      {model_content && <DialogContent>{model_content}</DialogContent>}
      {model_actions && <DialogActions>{model_actions}</DialogActions>}
    </Dialog>
  );
};
