import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAssessments } from '../api/getAllAssessments.api';

interface AssessmentState {
  loading: boolean;
  allAssessments: any;
  isError: boolean;
  errorMessage: string;
}

const initialState: AssessmentState = {
  loading: false,
  allAssessments: [],
  isError: false,
  errorMessage: ''
};

export const getAllAssessmentData = createAsyncThunk(
  'allAssessment/getAllAssessmentData',
  async (payload: { email }, { rejectWithValue }) => {
    try {
      const response = await getAllAssessments(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allAssessmentSlice = createSlice({
  name: 'allAssessmentData',
  initialState,
  reducers: {
    clearAllAssessmentData: (state: any, action) => {
      state.allAssessments = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAssessmentData.pending, (state) => {
        state.loading = true;
        state.allAssessments = [];
      })
      .addCase(getAllAssessmentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.allAssessments = action?.payload?.results ?? [];
        }
      })
      .addCase(getAllAssessmentData.rejected, (state, action: any) => {
        state.loading = false;
        state.isError = true;
        state.allAssessments = [];
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});
export const { clearAllAssessmentData } = allAssessmentSlice.actions;
export default allAssessmentSlice.reducer;
