import { apiGet, apiPost } from '../../../shared/utils/api-request';

export const getAssessmentQuestionsListAPI = async (payload: {
  tenantId: string;
  unitId: string;
  userId: string;
  isVerifiedUser?: boolean;
}) => {
  if (payload.isVerifiedUser) {
    const res = await apiPost(
      `tenant/${payload.tenantId}/tenant-user/${payload.userId}/unit/${payload.unitId}/assessment`
    );
    return res.data;
  } else {
    const res = await apiPost(
      `/user/${payload.userId}/unit/${payload.unitId}/assessment`
    );
    return res.data;
  }
};
