import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';
import { GuardedRoute } from './guarded-routes';
import * as MODULE from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => MODULE.LOGIN));
const LandingPage = Loader(lazy(() => MODULE.LANDING_PAGE));

// Dashboards

const Organazation = Loader(lazy(() => MODULE.ORGANIZATION));
const AdminManagement = Loader(lazy(() => MODULE.ADMIN_MANAGEMENT));
const UserManagement = Loader(lazy(() => MODULE.USER_MANAGEMENT));
const Assessment = Loader(lazy(() => MODULE.ASSESSMENT));
const RecordAssessment = Loader(lazy(() => MODULE.RECORD_ASSESSMENT));
const Settings = Loader(lazy(() => MODULE.SETTINGS));
const Reports = Loader(lazy(() => MODULE.REPORTS));
const ShowAllAssessment = Loader(lazy(() => MODULE.SHOW_ALL_ASSESSMENT));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <LandingPage />
      },
      {
        path: `tenant/:tenant_id/unit/:unit_id`,
        element: <Login />
      },
      {
        path: `login`,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout className="admin-login" />,
    children: [
      {
        path: ROUTES.ADMIN_LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: `${ROUTES.DASHBOARD}`,
        element: <Organazation />
      },

      {
        path: `tenant/:tenant_id/user/:user_id/unit/:unit_id`,
        element: <Assessment />
      },
      {
        path: `${ROUTES.SHOW_ALL_ASSESSMENTS}`,
        element: <ShowAllAssessment />
      },
      {
        path: `${ROUTES.REPORTS}`,
        element: <Reports />
      },
      {
        path: `${ROUTES.ASSESSMENT}`,
        element: <Assessment />
      },

      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.TENANT_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ADMIN_MANAGEMENT}`,
        element: <GuardedRoute component={AdminManagement} />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ASSESSMENT}`,
        // element: <GuardedRoute component={Assessment} />
        element: <Assessment />
      },
      {
        path: `${ROUTES.ASSESSMENT}`,
        element: <Assessment />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.RECORD_ASSESSMENT}`,
        element: <RecordAssessment />
      },
      {
        path: `${ROUTES.RECORD_ASSESSMENT}`,
        element: <RecordAssessment />
      },
      {
        path: `${ROUTES.USER_MANAGEMENT}`,
        element: <UserManagement />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.SETTINGS}`,
        element: <Settings />
      }
    ]
  },
  {
    path: ROUTES.LANDING_PAGE,
    element: <LandingPage />
  }
];

export default routes;
