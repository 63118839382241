import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import transactionReducer from '../../modules/Transactions/services/transaction.service';
import organizationReducer from '../../modules/Organization/services/organization.service';
import adminUserDataReducer from '../../modules/AdminManagement/services/adminManagement.service';
import userManagementDataReducer from '../../modules/UserManagement/services/userManagement.service';
import userReducer from '../../auth/login/services/userSlice';
import assessmentReducer from '../../modules/Assessment/services/assessment.service';
import themeReducer from '../../modules/Settings/services/settings.service';
import recordAssessment from '../../modules/RecordAssessment/services/recordAssessment.service';
import storage from 'redux-persist/lib/storage';
import allAssessmentService from '../../modules/ShowAllAssessment/services/allAssessment.service';

const combinedReducer = combineReducers({
  user: userReducer,
  organizationsData: organizationReducer,
  adminUserData: adminUserDataReducer,
  transactionData: transactionReducer,
  userData: userReducer,
  userManagementData: userManagementDataReducer,
  assessmentsData: assessmentReducer,
  theme: themeReducer,
  recordAssessment: recordAssessment,
  allAssessmentService: allAssessmentService
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  if (action.type === 'user/logout') {
    console.log('Logout called');
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
