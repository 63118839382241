import { Box, Button as MuiButton } from '@mui/material';

import { CombinedProps } from './services/fileButtonInterface';
import React, { useEffect } from 'react';
import Typography from '../typography/Typography';
import { useTranslation } from 'react-i18next';
import { I18N } from 'src/shared/constants/constants';
import { showErrorMessage } from '../toaster/Toast';

export const DEFAULT_FILE_TYPES = ['image/png'];

export const FileButton = ({
  required = false,
  fileType = DEFAULT_FILE_TYPES,
  variant,
  label,
  isMultipleFile = false,
  fieldId,
  value, // Settinhg File Upload Value
  onChange,
  handleFileDelete,
  enableDelete = true,
  buttonName,
  maxFilesToUpload,
  ...props
}: CombinedProps) => {
  //const
  const { t } = useTranslation(I18N.DEFAULT);
  const acceptedTypes = fileType.toString();

  //state variables
  const [uploadedFiles, setUploadedFiles] = React.useState<any>([]);
  const [isButtonDisable, setIsButtonDisable] = React.useState<boolean>(false);

  //methods
  useEffect(() => {
    if (value && value.length > 0 && typeof value[0] != 'string') {
      setUploadedFiles(value);
    }
  }, [value]);

  useEffect(() => {
    if (
      (!isMultipleFile && uploadedFiles && uploadedFiles?.length !== 0) ||
      (maxFilesToUpload && uploadedFiles.length >= maxFilesToUpload)
    ) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  }, [uploadedFiles]);

  const handleChangeFile = (event: any): void => {
    event.persist();
    // If you want to generate any blob url, then uncomment the lines below
    // var file = event.target.files[0];
    // var imgURL = URL.createObjectURL(file);
    // console.log(`imgURL:${imgURL}`);
    if (event.target.files.length === 0) return;
    if (
      maxFilesToUpload &&
      uploadedFiles.length + event.target.files.length > maxFilesToUpload
    ) {
      return showErrorMessage(
        `File upload limit reached. You can upload upto ${maxFilesToUpload} files`,
        {
          position: 'top-right'
        }
      );
    }

    // const filesList = event.target.files;
    // setUploadedFiles(filesList);
    // let result: typeof filesList;
    // if (filesList.length) {
    //   //const arrFiles = Array.from(filesList);
    //   // ToDo - need to be modified
    //   // const fileName: Array<string> = [];

    //   result = [...filesList].filter((file: any, index: number): any => {
    //     //console.log(`${JSON.stringify({ file, index })}`);
    //     const fileTypeExtracted: string = file.type;
    //     if (fileType.includes(fileTypeExtracted)) {
    //       // if (arrFiles.length > index) {
    //       //   fileName.push(file.name);
    //       // }
    //       return { file, id: index };
    //     }
    //   });

    //   console.log(`Result:${JSON.stringify(result)}`);
    // }
    // onChange(result );
    const filesList = event.target.files;
    setUploadedFiles(filesList);
    let result: typeof filesList;
    if (filesList.length) {
      const arrFiles = Array.from(filesList);
      // ToDo - need to be modified
      // const fileName: Array<string> = [];

      result = arrFiles.filter((file: any, index: number): any => {
        const fileType: string = file.type;
        if (fileType.includes(fileType)) {
          // if (arrFiles.length > index) {
          //   fileName.push(file.name);
          // }
          return { file, id: index };
        }
      });
    }
    onChange(result);
  };

  return (
    <>
      <Box className="d-flex alignitem">
        {label && <Typography variant="h4">{label}</Typography>}
        {isMultipleFile && uploadedFiles && uploadedFiles.length > 0 && (
          <Box className="ml-37">
            <label htmlFor={fieldId}>
              <MuiButton
                {...props}
                variant={variant}
                component="span"
                disabled={isButtonDisable}
              >
                {t('addButton')}
              </MuiButton>
            </label>
          </Box>
        )}
      </Box>
      <Box className="fileUploads mt-28">
        <Box className="flex-basic-start">
          <input
            accept={acceptedTypes}
            id={fieldId}
            multiple={isMultipleFile}
            type="file"
            onChange={(e) => handleChangeFile(e)}
            disabled={isButtonDisable}
            hidden
            onBlur={() => {}}
          />

          {!(isMultipleFile && uploadedFiles && uploadedFiles?.length) && (
            <Box className="mr-30">
              <label htmlFor={fieldId}>
                <MuiButton
                  disabled={isButtonDisable}
                  {...props}
                  variant={variant}
                  component="span"
                  onBlur={() => {}}
                >
                  {buttonName}
                </MuiButton>
              </label>
            </Box>
          )}

          <Box>
            {uploadedFiles &&
              uploadedFiles?.length !== 0 &&
              Array.from(uploadedFiles).map((file: any, idx: number) => (
                <Box
                  key={idx}
                  className={`flex-basic-start ${
                    uploadedFiles?.length > 1 && 'mb-16'
                  }`}
                >
                  <Box className="mr-6">
                    <span className="icon-reports icon-size-34"></span>
                  </Box>
                  <Box className="text-medium"> {file?.name}</Box>
                  {enableDelete && (
                    <Box className="ml-10">
                      <span
                        className="icon-delete cursorPointer"
                        onClick={() => {
                          // setUploadedFiles([]);
                          const filteredFiles = uploadedFiles.filter(
                            (file: any, index: number) => index !== idx
                          );
                          setUploadedFiles(filteredFiles);
                          handleFileDelete(file);
                        }}
                      ></span>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};
